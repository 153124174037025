import React, { useCallback, useEffect, useState } from "react"
import { Label, Input } from "reactstrap"
import { useLocation, useHistory } from "react-router-dom"
import CurrencyInput from "react-currency-input-field"
import debounce from "lodash.debounce"
import { useRedemptionData } from "../../../../Context/redemption"
import { usePurchaseData } from "../../../../Context/purchase"
import { RedeemCodes, AddToDonationCart } from "../../../../Code/Data"
import { redeemCodeValidation } from "../../../../Code/Utilities"
import Modal from "../../../Shared/Modal"
import LoadingSpinner from "../../../Common/LoadingSpinner"
import { Button } from "../../../"
import AddToDonationCheckout from "../../../../Contents/ShoppingCart/AddToDonationCheckout"
import { srcPrepaidCard } from "../../../../images"
import styles from "./styles.module.scss"

function Step1({ onContinue, setSuccess, codeAsEntered: codeAsWasEntered, charityId, rc, codes, setCodes, loggedInUser }) {
  const [errorMessage, setErrorMessage] = useState()
  const [codeAsEntered, setCodeAsEntered] = useState(codeAsWasEntered || "")
  const [code, setCode] = useState(codeAsWasEntered || "")
  const [loading, setLoading] = useState()
  const { addToDonationData, setAddToDonationData } = usePurchaseData()
  const { amount } = addToDonationData
  const [codeValid, setCodeValid] = useState(!!rc)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [urlCharityId, setUrlCharityId] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isActionModalOpen, setActionModalOpen] = useState(false)
  const [directRedemption, setDirectRedemption] = useState(false)
  const [shoppingCart, setShoppingCart] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const {
    redemptionData, getRedeemCodeInfo, setRedemptionData, setRedeemCodeInfo, redeemCodeInfo,
  } = useRedemptionData()
  const { search } = useLocation()
  const history = useHistory()
  const { HasRedeemed, BrandEcard, LogoURL, HideAmount } = redeemCodeInfo || {}

  useEffect(() => {
    if (redeemCodeValidation(codeAsEntered)) {
      setCodeValid(true)
      setErrorMessage(null)
    }
    else {
      setErrorMessage(HasRedeemed ? "The code entered has already been redeemed." : redeemCodeInfo?.ErrorMessage)
    }
    if (charityId) {
      setUrlCharityId(charityId)
    }
  }, [])

  // Handle invalid format
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (errorMessage && !redeemCodeValidation(code)) {
        setCodeValid(false)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [errorMessage])

  // Handle redeemed code
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (HasRedeemed) {
        setCodeValid(false)
        setErrorMessage("The code has already been redeemed.")
      }
    }, 100)
    return () => clearTimeout(delayDebounceFn)
  }, [HasRedeemed])

  useEffect(() => {
    setCodeAsEntered(rc)
    setCode(rc)
  }, [search])

  useEffect(() => {
    async function handleDirectRedemption() {
      if (directRedemption) {
        await RedeemDirectlyToCharity()
      }
    }

    handleDirectRedemption()
  }, [directRedemption])


  const delayDebounceFn = useCallback(
    debounce(async (code) => {
      try {
        const res = await getRedeemCodeInfo(code)
        if (charityId && res && !res.CharityId) {
          setUrlCharityId(charityId)
        }
        const message = !code
          ? "Please enter a Code"
          : (res.Succeeded
            ? ""
            : (!!res.ErrorMessage
              ? res.ErrorMessage
              : "Please check the Code"))
        setErrorMessage(message)
        setRedeemCodeInfo(res)
        setCodeValid(res.Succeeded)
        if (res?.RedeemInfo?.HasCharityId) {
          setDirectRedemption(true)
        }
        if (res?.RedeemInfo?.UserRedemptionPageInfo) {
          history.push(`/Redeem/Custom/${res?.RedeemInfo.UserRedemptionPageInfo.UserId}/?RC=${codeAsEntered}`)
        }
      } catch (err) {
        setErrorMessage(err.error)

      }
    }, 1000),
    []
  );

  const RedeemDirectlyToCharity = async () => {
    setDirectCharityId()
    const payload = {
      RedeemCode: redeemCodeInfo.RedeemCode,
      Charities: [{
        CharityId: redeemCodeInfo.CharityId,
      }],
      ShareRedeemerInfo: redeemCodeInfo?.ShowDonor,
    }
    payload.Charities[0].Amount = +redeemCodeInfo.AmountToRedeem
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    // if (selectedCharity) {
    //   setRedemptionData({
    //     ...redemptionData, Charities: [selectedCharity],
    //   })
    // }
    if (Succeeded !== undefined && Succeeded) {
      setSuccess(true)
    }
    else {
      setErrorMessage(ErrorMessage)
    }
  }
  const handleChangeCode = (value) => {
    setCode(value);
    delayDebounceFn(value);
    if (!code) setCodeValid(false)
  }
  const handleChangeAmount = event => {
    if (event === undefined) {
      event = 0
    }
    setAddToDonationData({
      ...addToDonationData, amount: event,
    })
  }
  const setDirectCharityId = () => {
    if (!redeemCodeInfo?.CharityId || (charityId || urlCharityId)) {
      const rci = redeemCodeInfo
      if (rci && !rci.CharityId) {
        rci.CharityId = parseInt(charityId || urlCharityId)
      }
      setRedeemCodeInfo({ ...rci })
    }
    // setSelectedCharity(charityList.find(({ CharityId }) => parseInt(CharityId) === parseInt(redeemCodeInfo.CharityId)))
  }

  const isValid = () => {
    return agreeTerms && codeValid
  }
  const handleChangeData = e => {
    setRedemptionData({
      ...redemptionData, [e.target.name]: e.target.value,
    })
  }
  const handleContinue = async () => {
    if (amount > 0 && !paymentSuccess) {
      if (!isActionModalOpen) {
        setActionModalOpen(true)
        return
      }
      else {
        setActionModalOpen(false)
        setAddToDonationData({
          ...addToDonationData, amount: 0,
        })
      }
    }
    setRedemptionData({
      ...redemptionData, code: code,
    })
    if (redeemCodeInfo?.HasCharityId || urlCharityId || charityId) {
      setDirectCharityId()
      const payload = {
        RedeemCode: redeemCodeInfo.RedeemCode,
        Charities: [{
          CharityId: redeemCodeInfo?.CharityId,
        }],
        ShareRedeemerInfo: redeemCodeInfo?.ShowDonor,
      }

      if (payload.Charities.length > 0) {
        payload.Charities[0].Amount = +redeemCodeInfo.AmountToRedeem
        const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
        if (Succeeded) {
          setRedemptionData({
            ...redemptionData,
            Charities: payload.Charities,
          })
          setSuccess(true)
        }
        else {
          setErrorMessage(ErrorMessage)
        }
      }
      else {
        setErrorMessage("Your redemption could not be completed. Please contact us if this issue recurrs")
      }
      return
    }
    onContinue()
  }

  const handlePaymentSuccess = async () => {
    setPaymentSuccess(true)
    setModalOpen(false)
    const res = await getRedeemCodeInfo(code)
    setRedeemCodeInfo(res);
  }
  const onPay = async () => {
    const result = await AddToDonationCart(amount)
    // setLoading(false)
    if (result?.Succeeded) {
      setShoppingCart(result.ShoppingCart.Items[0])
      setModalOpen(true)
    }
    setActionModalOpen(false)
  }
  const renderRedeemInput = () => {
    const logoImage = ((BrandEcard && LogoURL) ? <div className={styles.title}>
      <img className={styles.imgLogo} src={LogoURL} alt="Corporate Logo" />
    </div> : null)

    const title = redeemCodeInfo?.AmountToRedeem && HideAmount === false && !!codeValid
      ? `You have $${redeemCodeInfo.AmountToRedeem} to redeem!`
      : "Let's redeem your Charity Gift!"


    return (<>
      {logoImage}
      {paymentSuccess &&
        <span className={styles.caption}>You have added ${amount || redeemCodeInfo?.AddedToDonationAmount} to this donation.</span>}
      <div className={styles.title}>{title}</div>
      <img
        className={styles.imgCard}
        src={redeemCodeInfo?.ImageUrl || srcPrepaidCard}
        alt=""
      />
      <div className={styles.redemptionDetail}>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputCode}
            name="code"
            maxLength={15}
            value={code || ""}
            onChange={(event) => handleChangeCode(event.target.value)}
            // onKeyUp={e => runOnceAfterwards(handleChangeCode(e.target.value.toUpperCase().trim()), 2000)}
            placeholder="Redeem Code"
          />
          <div className={styles.linkChangeCode}
            onClick={() => handleChangeCode(code)}>{!!code ? "Change Code" : "Enter Code"}</div>
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputEmail}
            style={{ fontSize: redemptionData?.email ? "130%" : "32px" }}
            name="email"
            maxLength={40}
            value={redemptionData?.email || ""}
            placeholder="Email Address"
            onChange={handleChangeData}
          />
          <div className={styles.lblEmail}>
            Optional: To receive a confirmation
          </div>
        </div>
      </div>
    </>)
  }

  return (
    <div className={styles.container}>
      <Modal
        isOpen={isActionModalOpen}
        onClose={() => setActionModalOpen(false)}
        contentLabel="Action dialog"
      >
        <p className="fs-4">Did you change your mind about adding ${amount} to this donation?</p>
        <button className={styles.yesBtn} onClick={() => handleContinue()}>Continue without adding to donation</button>
        <button className={styles.noBtn} onClick={() => onPay()}>Add ${amount} Now</button>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        contentLabel="Action dialog"
      >
        <AddToDonationCheckout item={shoppingCart} amount={amount} onPaymentSuccess={handlePaymentSuccess}
          code={code} />
      </Modal>
      <div className={styles.content}>
        {renderRedeemInput()}
        {(!codeValid || !redeemCodeInfo || !redeemCodeInfo.AmountToRedeem)
          ? ((!!errorMessage || !codeValid)
            ? <div className={styles.errorMessage}>{errorMessage}</div>
            : <LoadingSpinner />)
          : <>
            <Label check className={styles.termsWrapper}>
              <Input
                className={styles.termsRadio}
                type="radio"
                checked={agreeTerms === true}
                onChange={() => setAgreeTerms(!agreeTerms)}
              />{" "}
              <span className={styles.lblTerms}>
                I affirm that I am the gift recipient or that I received this code
                in a rewards program by me redeeming my points. View our &nbsp;
                <a href="/FAQs.aspx#Link24">Terms and Conditions.</a>
              </span>
            </Label>
            <Button
              className={`${styles.btnContinue} m-3`}
              disabled={!isValid()}
              onClick={handleContinue}>
              Continue
            </Button>
            {codeValid && redeemCodeInfo?.HasCharityId !== true && !paymentSuccess &&
              <div className={styles.divAddToDonation}>
                <div>
                  To add to this donation, enter an amount
                </div>
                <div
                  title={"When you designate your charity gift, you may add your own funds to increase the benefit for your favorite charities. The total sum of your charity gift and whatever you have added, will be divided equally amongst the chosen charities. You will receive an emailed tax receipt for the additional funds."}>
                  <CurrencyInput className={styles.amountInput} onValueChange={handleChangeAmount}
                    allowNegativeValue={false} prefix={"$"} value={amount} />
                </div>
                <div>
                  {amount > 0 &&
                    <div className={styles.addToDonationBtn} loading={loading} onClick={() => onPay()}> Add
                      ${amount} Now </div>
                  }
                </div>
              </div>}
          </>
        }
      </div>
    </div>)
}

export default Step1

