import { useEffect, useRef } from 'react'

export function AutoHeightTextarea({ placeholder, value, id, name, className, rows, onChange }) {
    const textAreaRef = useRef()

    useEffect(() => {
        resizeMe()
    }, [value])

    const resizeMe = () => {
        const ta = textAreaRef.current
        if (ta) {
            // We need to reset the height first to get the correct scrollHeight for the textarea
            ta.style.height = '0px'
            const { scrollHeight } = ta
            const paddingTop = parseInt(window.getComputedStyle(ta, null).getPropertyValue('padding-top')) || 0
            const paddingBottom = parseInt(window.getComputedStyle(ta, null).getPropertyValue('padding-bottom')) || 0
            // Now we set the height directly
            ta.style.height = `${scrollHeight - paddingTop - paddingBottom}px`
        }
    }


    return (
        <textarea
            ref={textAreaRef}
            id={id}
            name={name}
            className={className}
            onChange={(e) => { resizeMe(); if (onChange) onChange(e) }}
            placeholder={placeholder}
            value={value}
            rows={rows}
        />
    )
}

