import React, { useEffect, useState } from "react"
import { Label, Input } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useRedemptionData } from "../../../../Context/redemption"
import { redeemCodeValidation } from "../../../../Code/Utilities"
import { Button } from "../../../"
import styles from "./styles.module.scss"
import { AutoHeightTextarea } from "../../../Common/AutoHeightTextArea"

function Step1Multi({ onContinue, setSuccess, codeAsEntered, charityId, rc, codes = [], setCodes, loggedInUser }) {
  const [errorMessages, setErrorMessages] = useState([])
  const [hasValidCode, setHasValidCode] = useState(false)
  const [newCodes, setNewCodes] = useState(codes)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const { redemptionData, getRedeemCodeInfo, setRedemptionData } = useRedemptionData()
  const { search } = useLocation()

  useEffect(() => {
    const codesToAdd = rc?.split(",")
    if (codesToAdd && codesToAdd.length > 0) setCodes(codesToAdd)
  }, [search])

  const handleNewCodes = async () => {
    setHasValidCode(false)
    if (!newCodes.length) {
      setCodes([])
      return
    }
    const list = newCodes.split('\n').map(c => c.trim()).filter(c => c)
    const newErrorMessages = []
    const addedCodes = []

    for (const c of list) {
      const newCode = c.toUpperCase()
      if (!redeemCodeValidation(newCode)) {
        newErrorMessages.push(`${newCode} is not a valid CharityChoice redeem code`)
        continue
      }
      const result = await getRedeemCodeInfo(newCode, loggedInUser?.UserId)
      if (!result.Succeeded) {
        newErrorMessages.push(`${newCode} is not a valid CharityChoice redeem code`)
        continue
      }
      else {
        setHasValidCode(true)
      }
      addedCodes.push(newCode)
    }

    if (addedCodes.length) {
      const unique = [...new Set([...codes, ...addedCodes])]
      setCodes(unique)
    }
    setErrorMessages(newErrorMessages)
  }

  const handleContinue = async () => {
    setRedemptionData({ ...redemptionData, code: codes.join(",") })
    onContinue()
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>Enter Your Redemption Codes</div>
        <div className={styles.multiInputWrapper}>
          <AutoHeightTextarea
            className={styles.multiInputCode}
            name="code"
            placeholder="Input Codes"
            value={newCodes}
            rows={3}
            onChange={(e) => setNewCodes(e.target.value)}
          />
          <Button
            className={`${styles.btnContinue} m-3`}
            onClick={handleNewCodes}>
            Apply Changes
          </Button>
        </div>
        <Label check className={styles.termsWrapper}>
          <Input
            className={styles.termsRadio}
            type="radio"
            checked={agreeTerms === true}
            onChange={() => setAgreeTerms(!agreeTerms)}
          />{" "}
          <span className={styles.lblTerms}>
            I affirm that I am the gift recipient or that I received this code
            in a rewards program by me redeeming my points. View our &nbsp;
            <a href="/FAQs.aspx#Link24">Terms and Conditions.</a>
          </span>
        </Label>
        {errorMessages.length > 0 && (
          <ul className={styles.errorList}>
            {errorMessages.map((em, index) => (
              <li key={index}>{em}</li>
            ))}
          </ul>
        )}
        <Button
          className={`${styles.btnContinue} m-3`}
          disabled={!codes.length || !agreeTerms || !hasValidCode}
          onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default Step1Multi
