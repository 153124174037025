import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { parseQuerystring } from "../../../Code/Utilities"
import { RedBar } from "../../"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step1Multi from "./Step1Multi"
import styles from "./styles.module.scss"
import { usePurchaseData } from "../../../Context/purchase"
import { useUserData } from "../../../Context/user"
import { useHistory } from "react-router-dom"

function Steps({ setSuccess }) {
  const [multiRedeem, setMultiRedeem] = useState(false)
  const [step, setStep] = useState(0)
  const { loggedInUser } = useUserData()
  const STEPS = multiRedeem ? [Step1Multi, Step2] : [Step1, Step2];
  const { pathname, search } = useLocation()
  const history = useHistory()
  const { RCAE, RC, CHARITYID } = parseQuerystring(window.location.search.toUpperCase())
  const [codes, setCodes] = useState([])


  useEffect(() => {
    const isMulti = pathname.includes("/multi")
    setMultiRedeem(isMulti)
    // /*** TODO - Remove when multi redemption is moved to this React app ************************
    // ***/if (isMulti) window.location.href = `/Redeem/MultipleRedemption.aspx${window.location.search}` /****
    // *******************************************************************************************/
  }, [pathname])

  const handleContinue = () => {
    setStep(step + 1)
  }
  const handleBack = () => {
    setStep(step - 1)
  }

  const handleMultiRedeem = () => {
    history.push(`/Redeem/multi/${window.location.search}`)
    // window.location = `/Redeem/multi/${window.location.search}`;
  }

  const StepComponent = STEPS[step]

  return (
    <>
      <RedBar>
        <div className={styles.stepLabel}>
          Step {step + 1} of {STEPS.length}
          {!multiRedeem && !step && (
            <div
              className={styles.btnMultiRedeem}
              onClick={handleMultiRedeem}>
              Redeem Multiple Codes
            </div>
          )}
        </div>
      </RedBar>

      <StepComponent
        onContinue={handleContinue}
        onBack={handleBack}
        setSuccess={setSuccess}
        loggedInUser={loggedInUser}
        codeAsEntered={!!RCAE ? RCAE : RC}
        rc={RC}
        codes={codes}
        setCodes={setCodes}
        charityId={CHARITYID ? parseInt(CHARITYID) : undefined}
        multi={multiRedeem}
        setMultiRedeem={setMultiRedeem} />
    </>
  )
}

export default Steps
